import React from "react";
import styles from "./Navbar.module.css";
import ServerHealth from "../ServerHealth";


export default function Navbar() {
  const NavbarData = [
    {
      name: "Scrapper",
      to: "/scrapper"
    },
    {
      name: "Offers",
      to: "/offers",
    },
    {
      name: "Blogs",
      to: "/blogs",
    }
  ];

  const token = localStorage.getItem("token");

  const logout = () => {
    localStorage.removeItem("token");
  };

  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.brand}>Mango</div>
        <ServerHealth />
        <ul className={styles.navItems}>
          {NavbarData.map((navItem, index) => {
            return (
              <li key={index}>
                <a href={navItem.to}>{navItem.name}</a>
              </li>
            );
          })}
          {token ? (
            <li key={"logout"} onClick={logout}>
              <a href="/">Logout</a>
            </li>
          ) : (
            <li key={"login"}>
              <a href="/">Login</a>
            </li>
          )}
        </ul>
      </nav>
      <hr />
    </>
  );
}
