// const headers = {
//   Referer: "react-mango-client.vercel.app",
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
//   "Content-Type": "application/json",
// }

// Create
export const Create = async (payload, token, what) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${what}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((response) => response.json());
};

// Delete
export const Delete = async (id, token, what) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${what}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

// Visibility
export const HandleVisibility = async (id, token, what) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${what}/visibility/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

// Read All
// export const ReadAll = async (token, what) => {
//   return fetch(`${process.env.REACT_APP_API_URL}/${what}s`, {
//     method: "GET",
//     headers: {
//       'Authorization': `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   }).then((response) => response.json());
// };
export const ReadAll = async (token, what) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${what}s`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return {}; // You can return an empty object or another default value in case of an error.
  }
};



// Read One
export const ReadOne = async (id, token, what) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${what}/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

// Update
export const Update = async (payload, token, what) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${what}/${payload.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((response) => response.json());
};

// // Activate / Deactivate
// export const Activate = async (id, token, what) => {
//   return fetch(`${process.env.REACT_APP_API_URL}/${what}/${id}`, {
//     method: "PUT",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   }).then((response) => response.json());
// };

// Get Categories
export const ReadCategoryList = async (token, what) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${what}s/categories`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

// onDeleteClick
export const onDeleteClick = async () => {
  return fetch(`${process.env.REACT_APP_API_URL}/scapping-initiate-delete`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    }
  }).then((response) => response.json());
};

// onNewFetchClick
export const onNewFetchClick = async () => {
  return fetch(`${process.env.REACT_APP_API_URL}/scrapping-initiate-fetch`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    }
  }).then((response) => response.json());
};

// onSendToTGClick
export const onSendToTGClick = async () => {
  return fetch(`${process.env.REACT_APP_API_URL}/sent-to-telegram`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    }
  }).then((response) => response.json());
};
