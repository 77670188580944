import React, { useState } from "react";
import LoginRequest from "../api/LoginRequest";
import { useNavigate } from "react-router-dom";
// import { TokenContext } from "../App";

export default function LoginPage() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  // const [authToken, setAuthToken] = useContext(TokenContext);
  const navigate = useNavigate();
  let token = localStorage.getItem("token");

  const handleLogin = (e) => {
    e.preventDefault();
    LoginRequest(password)
      .then(({ token }) => {
        // setAuthToken(token);
        localStorage.setItem("token", token);
        // navigate("/offers");
        // eslint-disable-next-line no-restricted-globals
        location.replace("/offers");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <div style={{ margin: "5rem" }}>
      {token ? (
        <div>Logged in</div>
      ) : (
        <>
          <h1>Login</h1>
          <div style={{ color: "red" }}>{error}</div>
          <form onSubmit={handleLogin}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button>Login</button>
          </form>
        </>
      )}
    </div>
  );
}
