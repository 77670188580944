import React, { useEffect, useState } from "react";
import {
  ReadOne,
  Update,
  Create,
  ReadCategoryList,
  HandleVisibility,
} from "../api";
// import { TokenContext } from "../App";
import styles from "../styles/BlogViewPage.module.css";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import Delta from "quill-delta";

Quill.register("modules/imageResize", ImageResize);
const ClipboardModule = Quill.import("modules/clipboard");

class CustomClipboard extends ClipboardModule {
  onPaste(event) {
    const range = this.quill.getSelection();
    const formats = this.quill.getFormat(range.index);
    const pastedDelta = this.convert(formats);
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .concat(pastedDelta);
    this.quill.updateContents(delta, "silent");
    // range.length contributes to delta.length()
    this.quill.setSelection(
      delta.length() - range.length,
      range.length,
      "silent"
    );
    this.quill.scrollIntoView();
  }
}

Quill.register("modules/clipboard", CustomClipboard, true);

function BlogViewPage() {
  // avoid page reload to save data
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "Are you sure you want to leave this page?";
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState("");
  const params = useParams();

  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [headerImage, setHeaderImage] = useState("");
  const [slug, setSlug] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [contentData, setContentData] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [authorSocialLink, setAuthorSocialLink] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // const [authToken] = useContext(TokenContext);

  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTY3NjcxMDk1M30.Vc4AwCCq8Occh2F3a9PYC-8qspkmH1YEJa7zTJTFVHs";

  const token = localStorage.getItem("token");

  const getCategories = async () => {
    let data = await ReadCategoryList(token, "blog");
    if (data.name) {
      setCategoryList([]);
    } else {
      setCategoryList(data);
    }
  };

  const fetchData = async (id) => {
    setIsLoading(true);
    let data = await ReadOne(id, token, "blog");

    setId(data?._id);
    setTitle(data?.title);
    setMetadesc(data?.metadesc);
    setHeaderImage(data?.header_image);
    setSlug(data?.slug);
    setContentData(data?.content);
    setCategory(data?.category);
    setIsActive(data?.isActive);
    setKeywords(data?.keywords);
    setAuthorName(data?.author?.name || "Nitesh Jain");
    setAuthorSocialLink(data?.author?.socialLink || "https://www.linkedin.com/in/initeshjain");
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      if (params.id !== "create-new") {
        fetchData(params.id);
        getCategories();
      } else {
        getCategories();
        setAuthorName("Nitesh Jain");
        setAuthorSocialLink("https://www.linkedin.com/in/initeshjain");
      }
    }
  }, []);

  // const deleteBlog = async (id) => {
  //   let confirmDelete = confirm("Do you really want to delete the blog?");
  //   if (confirmDelete) {
  //     let response = await Delete(id, token, "blog");
  //     setServerMessage(response.message);
  //     navigate("/blogs");
  //   }
  // };

  const handleVisibility = async (id, isActive) => {
    let what = isActive ? "deactivate" : "activate";
    // eslint-disable-next-line no-restricted-globals
    let confirmChange = confirm(`Do you really want to ${what} the blog?`);
    if (confirmChange) {
      let response = await HandleVisibility(id, token, "blog");
      // console.log(response);
      fetchData();
    }
  };

  const UpdateData = async () => {
    let payload = {
      id,
      metadesc,
      header_image: headerImage,
      content: contentData,
      slug,
      title,
      category,
      keywords,
      author: {
        name: authorName,
        socialLink: authorSocialLink
      }
    };

    let response = await Update(payload, token, "blog");
    setServerMessage(response.message);
    navigate(`/blog/${id}`);
  };

  const AddNew = async () => {
    let payload = {
      id,
      metadesc,
      header_image: headerImage,
      content: contentData,
      slug,
      title,
      category,
      keywords,
      author: {
        name: authorName,
        socialLink: authorSocialLink
      }
    };

    let response = await Create(payload, token, "blog");
    setServerMessage(response.message);
    navigate(`/blog/${response._doc._id}`);
  };

  const Process = () => {
    if (params.id !== "create-new") {
      UpdateData();
    } else {
      AddNew();
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }], // Font and background color options
      [{ font: [] }], // Font family options
      [{ align: [] }], // Alignment options
      [{ 'script': 'sub' }, { 'script': 'super' }],
      ["link", "image"],
      ["clean"],
      ["code-block"],
      [{ 'direction': 'rtl' }],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "code-block",
    "color",
    "font",
    "background",
    "align",
    "script",
    "direction"
  ];

  const quillRef = React.createRef();

  // Register a custom image handler to embed image links
  useEffect(() => {
    const quill = quillRef.current.getEditor();

    quill.getModule("toolbar").addHandler("image", () => {
      const url = prompt("Enter the URL of the image:");
      if (url) {
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", url, Quill.sources.USER);
      }
    });
  }, []);

  return (
    <div className={styles.main_div}>
      {/* <pre>{JSON.stringify(blog, null, 2)}</pre> */}
      {!token ? (
        <div>PLEASE LOG IN</div>
      ) : (
        <>
          <div className={styles.message}>
            <p>server message: {serverMessage}</p>
          </div>

          <h5>Title</h5>
          <input
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <h5>Content</h5>
          <ReactQuill
            ref={quillRef}
            name="content"
            theme="snow"
            value={contentData}
            onChange={setContentData}
            modules={modules}
            formats={formats}
            className={styles.editorInput}
          />

          <h5>Slug</h5>
          <input
            type="text"
            name="slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />

          <h5>Header Image</h5>
          <input
            type="text"
            name="header_image"
            value={headerImage}
            onChange={(e) => setHeaderImage(e.target.value)}
          />

          <h5>Metadesc</h5>
          <textarea
            type="text"
            name="metadesc"
            value={metadesc}
            onChange={(e) => setMetadesc(e.target.value)}
          />

          <h5>Keywords (comma separated values)</h5>
          <textarea
            type="text"
            name="keywords"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />

          <h5>Category</h5>
          <input
            type="text"
            name="category"
            value={category}
            list="categorydatalist"
            onChange={(e) => setCategory(e.target.value)}
          />

          <datalist id="categorydatalist">
            {categoryList.length !== 0 &&
              categoryList.map((cat, i) => {
                return (
                  <option key={i} value={cat}>
                    {cat}
                  </option>
                );
              })}
          </datalist>

          <h5>Author Name</h5>
          <input
            type="text"
            name="author_name"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
          />

          <h5>Author Social Link</h5>
          <input
            type="text"
            name="author_social_link"
            value={authorSocialLink}
            onChange={(e) => setAuthorSocialLink(e.target.value)}
          />

          <div className={styles.btns}>
            <button
              className={[styles.btn, styles.del_btn].join(" ")}
              onClick={() => handleVisibility(id, isActive)}
            >
              {isActive ? "Deactivate" : "Activate"}
            </button>

            <button
              className={[styles.btn, styles.view_btn].join(" ")}
              onClick={() => Process()}
            >
              Save
            </button>
            {isLoading && <span>Loading...</span>}
          </div>
        </>
      )}
    </div>
  );
}

export default BlogViewPage;
