import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
};

export const handleUpload = async (file) => {
  let newFileName = file.name.replace(/\..+$/, "");

  newFileName = `${newFileName}_${
    Date.now() - Math.floor(Math.random() * 100000000)
  }`;

  const ReactS3Client = new S3(config);
  let v = ReactS3Client.uploadFile(file, newFileName).then((data) => {
    console.log(data);
    if (data.status === 204) {
      console.log("success");
      return data.location;
    } else {
      console.log("fail");
      return "Error";
    }
  });

  return v;
};
