import React, { useState } from "react";
import { onDeleteClick, onNewFetchClick, onSendToTGClick } from "../api";
import styles from "../styles/OfferLoaderPage.module.css";

export default function OfferLoaderPage() {

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const onDelete = async () => {
    setButtonDisabled(true);
    await onDeleteClick();
    setButtonDisabled(false);
  }

  const onNewFetch = async () => {
    setButtonDisabled(true);
    await onNewFetchClick();
    setButtonDisabled(false);
  }

  const onTGSend = async () => {
    setButtonDisabled(true);
    await onSendToTGClick();
    setButtonDisabled(false);
  }

  return (
    <div className={styles.container}>
      <button
        className={[styles.button, styles.button_red].join(" ")}
        disabled={isButtonDisabled}
        onClick={() => onDelete()}>
        Delete All Offers
      </button>

      <button
        className={[styles.button, styles.button_yellow].join(" ")}
        disabled={isButtonDisabled}
        onClick={() => onNewFetch()}>
        Add New Offers
      </button>

      <button
        className={[styles.button, styles.button_blue].join(" ")}
        disabled={isButtonDisabled}
        onClick={() => onTGSend()}>
        Send Offers To Telegram
      </button>
    </div>
  )

}