import React, { useEffect, useState } from "react";
import { ReadAll, Delete, HandleVisibility } from "../api";
// import { TokenContext } from "../App";
import styles from "../styles/List.module.css";
import { useNavigate } from "react-router-dom";

function BlogsPage() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // const [authToken] = useContext(TokenContext);
  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTY3NjcxMDk1M30.Vc4AwCCq8Occh2F3a9PYC-8qspkmH1YEJa7zTJTFVHs"
  const token = localStorage.getItem("token");

  const fetchData = async () => {
    let data = await ReadAll(token, "blog");
    setBlogs(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const deleteBlog = async (id) => {
  //   let confirmDelete = confirm("Do you really want to delete the blog?");
  //   if (confirmDelete) {
  //     let response = await Delete(id, token, "blog");
  //     fetchData();
  //   }
  // };

  const handleVisibility = async (id, isActive) => {
    let what = isActive ? "deactivate" : "activate";
    // eslint-disable-next-line no-restricted-globals
    let confirmChange = confirm(`Do you really want to ${what} the blog?`);
    if (confirmChange) {
      let response = await HandleVisibility(id, token, "blog");
      fetchData();
    }
  };

  return (
    <div className={styles.main_container}>
      <h4>Blogs</h4>
      <button
        className={[styles.btn, styles.view_btn].join(" ")}
        onClick={() => navigate("/blog/create-new")}
      >
        Add blog
      </button>
      {/* <pre>{JSON.stringify(blogs, null, 2)}</pre> */}
      {isLoading ? (
        "Loading..."
      ) : (
        <div className={styles.card_container}>
          {blogs.map((ele, index) => {
            return (
              <div key={ele._id} className={styles.card}>
                <h5>{ele.title}</h5>
                {/* <p className={styles.metadesc}>{ele.metadesc}</p> */}
                <div className={styles.btns}>
                  {/* <button
                    className={[styles.btn, styles.del_btn].join(" ")}
                    onClick={() => deleteBlog(ele._id)}
                  >
                    Delete
                  </button> */}
                  <button
                    className={[styles.btn, styles.del_btn].join(" ")}
                    onClick={() => handleVisibility(ele._id, ele.isActive)}
                  >
                    {ele.isActive ? "Deactivate" : "Activate"}
                  </button>
                  <button
                    className={[styles.btn, styles.view_btn].join(" ")}
                    onClick={() => navigate(`/blog/${ele._id}`)}
                  >
                    View / Update
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default BlogsPage;
