import React, { useEffect, useState } from "react";
import styles from "./ServerHealth.module.css"

export default function () {
  const [isLoading, setIsLoading] = useState(false);
  const [isServerOk, setIsServerOk] = useState(false);

  useEffect(() => {
    checkHealth();
  }, [])

  const checkHealth = async () => {
    setIsLoading(true);
    try {
      const res = await (await fetch(`${process.env.REACT_APP_API_URL}/health`)).json();
      if (res.ok) {
        setIsServerOk(true);
      }
    } catch (e) {
      console.error(e);
      setIsServerOk(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.container}>

      Server: {
        isLoading ? <span title="checking...">🟡</span> : isServerOk ? <span title="ok">🟢</span> : <span title="not ok">🔴</span>
      }

      <button disabled={isLoading} onClick={checkHealth} className={styles.button}>
        Recheck
      </button>

    </div>
  )
}
