import React from "react";
// import reactLogo from "./assets/react.svg";
import styles from "./App.module.css";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import BlogsPage from "./pages/BlogsPage";
import BlogViewPage from "./pages/BlogViewPage";
import OffersPage from "./pages/OffersPage";
import OfferViewPage from "./pages/OfferViewPage";
import OfferLoaderPage from "./pages/OfferLoaderPage";

// export const TokenContext = React.createContext(null);

const ProtectedRoute = ({ element }) => {
  // const [authToken] = useContext(TokenContext);

  const token = localStorage.getItem("token");

  return token ? element() : <Navigate to="/" />;
};

function App() {
  // const [authToken, setAuthToken] = useState(null);
  return (
    <div className={styles.app}>
      {/* <TokenContext.Provider value={[authToken, setAuthToken]}> */}
      <Router>
        <Navbar />
        <div className={styles.components}>
          <Routes>
            {/* <Route
              path="/offers"
              element={<ProtectedRoute element={OffersPage} />}
            />
            <Route
              path="/offer/:id"
              element={<ProtectedRoute element={OfferViewPage} />}
            /> */}
            <Route path="/offers" element={<OffersPage />} />
            <Route path="/offer/:id" element={<OfferViewPage />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/blog/:id" element={<BlogViewPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/scrapper" element={<OfferLoaderPage />} />
          </Routes>
        </div>
      </Router>
      {/* </TokenContext.Provider> */}
    </div>
  );
}

export default App;
