export default async function LoginRequest(password) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password,
    }),
  });
  if (response.ok) {
    return response.json();
  } else {
    throw new Error("Login failed");
  }
}
