import React, { useEffect, useState, useRef } from "react";
import {
  ReadOne,
  Delete,
  Update,
  Create,
  ReadCategoryList,
  HandleVisibility,
} from "../api";
// import { TokenContext } from "../App";
import styles from "../styles/OfferViewPage.module.css";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";

import { handleUpload } from "../components/S3";

window.Buffer = window.Buffer || require("buffer").Buffer;

Quill.register("modules/imageResize", ImageResize);

function OfferViewPage() {
  // avoid page reload to save data
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "Are you sure you want to leave this page?";
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState("");
  // const [token, setToken] = useState("");
  const params = useParams();

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [otherImage, setOtherImage] = useState([]);
  const [slug, setSlug] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [buyLink, setBuyLink] = useState("");
  const [offerText, setOfferText] = useState("");
  const [discount, setDiscount] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [isActive, setIsActive] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [mainImageUrl, setMainImageUrl] = useState([]);
  const [otherImageUrls, setOtherImageUrls] = useState([]);

  // const fileInput = useRef();

  // let mainImages = [];
  // mainImages = fileInput?.current?.files;

  // useEffect(() => {
  //   // mainImages = fileInput.current.files;
  //   console.log("images", fileInput?.current?.files);
  // }, [fileInput?.current]);

  const handleMainImageChange = (event) => {
    // mainImages = fileInput?.current?.files;
    // let files = [];
    // files = Array.from(fileInput?.current?.files);
    // let urls = files.map((file) => URL.createObjectURL(file));

    // setImageUrls([...imageUrls, ...Array.from(event.target.files)]);
    setMainImageUrl([...Array.from(event.target.files)]);
    // setImageUrls(Array.from(event.target.files));
  };

  const handleOtherImageChange = (event) => {
    setOtherImageUrls([...otherImageUrls, ...Array.from(event.target.files)]);
  };

  const RemoveOtherImagefromArray = (index) => {
    let tempArray = otherImageUrls;
    tempArray.splice(index, 1);
    setOtherImageUrls([...tempArray]);
  };

  const RemoveMainImagefromArray = (index) => {
    let tempArray = mainImageUrl;
    tempArray.splice(index, 1);
    setMainImageUrl([...tempArray]);
  };

  // const [authToken] = useContext(TokenContext);

  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTY3NjcxMDk1M30.Vc4AwCCq8Occh2F3a9PYC-8qspkmH1YEJa7zTJTFVHs"
  const token = localStorage.getItem("token");

  const handleMainImageUpload = async (event) => {
    event.preventDefault();
    let mi = mainImageUrl[0];
    let res = await handleUpload(mi);
    if (res !== "Error") {
      setMainImage(res);
    }
  };

  const handleOtherImagesUpload = async (event) => {
    event.preventDefault();
    let newArr = otherImageUrls;
    for (let i = 0; i < newArr.length; i++) {
      let res = await handleUpload(newArr[i]);
      if (res !== "Error") {
        setOtherImage((prev) => [...prev, res]);
      }
    }
  };

  console.log("main other state", otherImage);

  const getCategories = async () => {
    let data = await ReadCategoryList(token, "offer");
    if (data.name) {
      setCategoryList([]);
    } else {
      setCategoryList(data);
    }
  };

  const fetchData = async (id) => {
    setIsLoading(true);
    let data = await ReadOne(id, token, "offer");

    setId(data?._id);
    setTitle(data?.title);
    setDescription(data?.description);
    setSlug(data?.slug);
    setMainImage(data?.main_image);
    setOtherImage(data?.other_image);
    setMetadesc(data?.metadesc);
    setBuyLink(data?.buy_link);
    setOfferText(data?.offer_text);
    setDiscount(data?.discount);
    setCategory(data?.category);
    setPrice(data?.price);
    setIsActive(data?.isActive);
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      if (params.id !== "create-new" && token !== "") {
        fetchData(params.id);
        getCategories();
      } else {
        getCategories();
      }
    }
  }, []);

  // const DeleteIt = async (id) => {
  //   let confirmDelete = confirm("Do you really want to delete the offer?");
  //   if (confirmDelete) {
  //     let response = await Delete(id, token, "offer");
  //     setServerMessage(response.message);
  //     navigate("/offers");
  //   }
  // };

  const handleVisibility = async (id, isActive) => {
    let what = isActive ? "deactivate" : "activate";
    // eslint-disable-next-line no-restricted-globals
    let confirmChange = confirm(`Do you really want to ${what} the offer?`);
    if (confirmChange) {
      let response = await HandleVisibility(id, token, "offer");
      fetchData();
    }
  };

  const UpdateData = async () => {
    let payload = {
      id,
      title,
      description,
      slug,
      main_image: mainImage,
      other_image: otherImage,
      metadesc,
      buy_link: buyLink,
      offer_text: offerText,
      discount,
      category,
      price,
    };

    let response = await Update(payload, token, "offer");
    setServerMessage(response.message);
    navigate(`/offer/${id}`);
  };

  const AddNew = async () => {
    let payload = {
      title,
      description,
      slug,
      main_image: mainImage,
      other_image: otherImage,
      metadesc,
      buy_link: buyLink,
      offer_text: offerText,
      discount,
      category,
      price,
    };

    let response = await Create(payload, token, "offer");
    setServerMessage(response.message);
    navigate(`/offer/${response._doc._id}`);
  };

  const Process = () => {
    if (params.id !== "create-new") {
      UpdateData();
    } else {
      AddNew();
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      ["code"],
      ["table"],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "code",
    "table",
  ];

  return (
    <div className={styles.main_div}>
      {/* <pre>{JSON.stringify(offer, null, 2)}</pre> */}
      {!token ? (
        <div>PLEASE LOG IN</div>
      ) : (
        <>
          <div className={styles.message}>
            <p>server message: {serverMessage}</p>
          </div>

          <h5>Title</h5>
          <input
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <h5>Description</h5>
          <div id="editor" className={styles.editor}>
            <ReactQuill
              name="description"
              theme="snow"
              value={description}
              // onChange={(e) =>
              //   onChangeEvent({ target: { name: "content", value: e } })
              // }
              onChange={setDescription}
              modules={modules}
              formats={formats}
              className={styles.editorInput}
            />
          </div>

          <h5>Slug</h5>
          <input
            type="text"
            name="slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            disabled={params.id === "create-new"}
          />

          <h5>Main Image</h5>
          <div className={styles.img_prvw}>
            {mainImageUrl?.map((file, index) => {
              return (
                <div
                  key={index}
                  className={styles.mainImage_preview}
                  onClick={() => RemoveMainImagefromArray(index)}
                >
                  <img src={URL.createObjectURL(file)} alt={file} />
                </div>
              );
            })}
          </div>
          <form onSubmit={handleMainImageUpload}>
            <div className={styles.inputWrapper}>
              <input
                type="file"
                name="main_image"
                className={styles.fileInput}
                // value={mainImage}
                // onChange={(e) => setMainImage(e.target.value)}
                // ref={fileInput}
                title="Select main image"
                onChange={(e) => handleMainImageChange(e)}
              />
            </div>
            <button type="submit">Upload</button>
          </form>

          <h5>Other Images</h5>
          <div className={styles.img_prvw}>
            {otherImageUrls?.map((file, index) => {
              return (
                <div
                  key={index}
                  className={styles.mainImage_preview}
                  onClick={() => RemoveOtherImagefromArray(index)}
                >
                  <img src={URL.createObjectURL(file)} alt={file} />
                </div>
              );
            })}
          </div>
          <form onSubmit={handleOtherImagesUpload}>
            <div className={styles.inputWrapper}>
              <input
                type="file"
                multiple
                name="other_image"
                className={styles.fileInput}
                // value={otherImage}
                title="Select other images"
                onChange={(e) => handleOtherImageChange(e)}
              />
            </div>
            <button type="submit">Upload</button>
          </form>

          <h5>Metadesc</h5>
          <input
            type="text"
            name="metadesc"
            value={metadesc}
            onChange={(e) => setMetadesc(e.target.value)}
          />

          <h5>Buy Link</h5>
          <input
            type="text"
            name="buy_link"
            value={buyLink}
            onChange={(e) => setBuyLink(e.target.value)}
          />

          <h5>Offer Text</h5>
          <input
            type="text"
            name="offer_text"
            value={offerText}
            onChange={(e) => setOfferText(e.target.value)}
          />

          <h5>Discount in %</h5>
          <input
            type="text"
            name="discount"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />

          <h5>Price</h5>
          <input
            type="number"
            name="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />

          <h5>Category</h5>
          <input
            type="text"
            name="category"
            value={category}
            list="categorydatalist"
            onChange={(e) => setCategory(e.target.value)}
          />

          <datalist id="categorydatalist">
            {categoryList.length !== 0 &&
              categoryList.map((cat, i) => {
                return (
                  <option key={i} value={cat}>
                    {cat}
                  </option>
                );
              })}
          </datalist>

          <div className={styles.btns}>
            {/* <button
              className={[styles.btn, styles.del_btn].join(" ")}
              onClick={() => DeleteIt(id)}
            >
              Delete
            </button> */}
            <button
              className={[styles.btn, styles.del_btn].join(" ")}
              onClick={() => handleVisibility(id, isActive)}
            >
              {isActive ? "Deactivate" : "Activate"}
            </button>
            <button
              className={[styles.btn, styles.view_btn].join(" ")}
              onClick={() => Process()}
            >
              Save
            </button>
            {isLoading && <span>Loading...</span>}
          </div>
        </>
      )}
    </div>
  );
}

export default OfferViewPage;
